/* Common Css for overall APP */
.form-background {
    margin-top: 2rem;
    background: white;
    padding: 40px;
    margin-bottom: 20px;
  }
  
  /* active tab color */
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #2f373b;
  }
  
  /* Inactive tab color */
  .ant-tabs-tab-btn {
    color: #878b8d;
  }
  
  /* body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
      Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  } */
  
  /*  button css */
  
  .styled-button {
    border-radius: 5px;
    height: 40px;
    font-family: Segoe UI, sans-serif !important;
  }
  
  /* float label css */
  .input-label {
    position: absolute;
    margin-bottom: -15px;
    margin-top: -10px;
    margin-left: 10px;
    background: white;
    padding-left: 5px;
    padding-right: 5px;
    z-index: 5;
    color: #a7a7a7;
  }
  /* antd input field for label in border*/
  .input-field {
    /* padding: 10px; */
    border-color: #e9e9e9;
    border-radius: 5px;
  }
  
  /* Input without label */
  .input-field-border {
    border-radius: 5px;
  }
  
  /* Overall Placeholder Style */
  ::placeholder {
    padding-left: 3px;
    font-size: 15px;
  }
  
  /* Checkbox design */
  .ant-checkbox-inner {
    width: 25px;
    height: 25px;
    border-radius: 7px;
  }
  .ant-checkbox-inner::after {
    top: 11px;
    left: 7px;
  }
  /* Tree Seelect Checkbox */
  .ant-tree-checkbox-inner {
    width: 25px;
    height: 25px;
    border-radius: 7px;
  }
  .ant-tree-checkbox-inner::after {
    top: 11px;
    left: 7px;
  }
  /* Single Select Input Design  */
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector {
    padding: 0 11px;
    border-radius: 5px;
    height: 47px;
    align-items: center;
  }
  
  /* Multiple  Select Input Design  */
  .ant-select-multiple.ant-select-show-arrow .ant-select-selector,
  .ant-select-multiple.ant-select-allow-clear .ant-select-selector {
    /* padding-right: 24px; */
    padding: 0 11px;
    border-radius: 5px;
    min-height: 47px;
    align-items: center;
  }
  
  .modal-button {
    width: 93px;
    height: 44px;
    border-radius: 5px;
    border-color: #e9e9e9;
    font-weight: bold;
  }
  
  .outlined-button {
    height: 72px;
    /* width: 150px; */
  }
  .ant-layout-sider-zero-width-trigger {
    top: 0;
    background: transparent;
  }
  
  .treeSelect.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 50px;
  }
  
  ul,
  li,
  ol {
    margin: 0px;
    padding: 0px;
    list-style: none;
  }
  h3.ant-typography {
    font-size: 38px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  h2.ant-typography {
    font-size: 38px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .all_pd span.ant-typography.ant-typography-secondary {
    font-size: 16px;
    font-weight: 600;
    color: #2f373b;
  }
  .input-field {
    padding-left: 22px;
    padding-right: 22px;
  }
  .all_pd .add_product_cnt,
  .all_pd .brands_bx_cnt,
  .all_pd .supplier_bx_cnt,
  .all_pd .store_bx_cnt,
  .all_pd .attribute_bx_cnt,
  .all_pd .category_bx_cnt,
  .all_pd .adduser_role_cnt,
  .all_pd .add_user_top,
  .all_pd .add_user_form,
  .add_merchant_top,
  .add_merchant_form,
  .all_pd .addpage_bx_cnt {
    max-width: 1053px;
    margin: 0 auto;
    width: 100%;
  }
  .ant-checkbox-inner {
    border-width: 2px;
  }
  .ant-radio-inner {
    width: 30px;
    height: 30px;
  }
  .ant-radio-inner::after {
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 7px;
    width: 14px;
    height: 14px;
    background: #ffffff;
  }
  hr {
    border: 1px solid #e2e2e2;
    height: 2px;
  }
  
  @media only screen and (max-width: 767px) {
    .ant-layout-sider-zero-width-trigger {
      background: #ffffff;
      top: 15px;
    }
    .top_right_bar {
      padding-top: 0px;
    }
  }
  .ant-avatar-lg.ant-avatar-image {
    border-radius: 8px;
  }
  .upload-area .ant-btn-danger {
    min-height: 58px;
    font-size: 16px;
    font-weight: 700;
    border-width: 2px;
    border-radius: 8px;
    padding: 5px 30px;
    background: #dc3545;
    border-color: #dc3545;
  }
  .upload-area .styled-button {
    background: #fff3ef;
    border-color: #fff3ef;
    min-height: 51px;
    font-size: 16px;
    font-weight: 700;
    color: #eb4a1c;
    border-width: 2px;
    border-radius: 8px;
    padding: 5px 30px;
    margin-right: 15px;
  }
  textarea {
    padding: 15px 20px !important;
  }
  .ant-avatar-lg {
    border-radius: 8px;
  }
  .ant-menu-title-content {
    align-items: center;
  }
  .ql-container {
    overflow-y: scroll;
    max-height: 300px;
  }
  
  .video-tutorial-right {
    position: relative;
  }
  .video-tutorial-right a {
    font-size: 16px;
    font-weight: 600;
    color: #2f373b;
    display: flex;
    align-items: center;
  }
  .video-tutorial-right a svg {
    font-size: 24px;
    margin-right: 10px;
    float: left;
    margin-top: 2px;
  }
  
  .video_list_in {
    min-width: 470px;
    padding: 25px;
    border-radius: 8px;
    background: #ffffff;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
      0 9px 28px 8px rgb(0 0 0 / 5%);
    transition: background 0.3s, width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
  }
  .list_in {
    background: none;
    max-height: 270px;
    overflow-y: scroll;
    margin-bottom: 0px;
    border: none;
  }
  .video_list_in .list_in li {
    padding: 0px;
    margin: 0px;
    border: 2px solid #e9e9e9;
    margin-bottom: 10px !important;
    border-radius: 8px;
    padding: 12px 20px;
    height: auto;
  }
  .video_list_in .list_in li:hover {
    background-color: transparent;
  }
  .video_list_in .list_in li a {
    margin: 0px;
    padding: 0px;
  }
  .video_list_in .list_in li.ant-menu-item-selected {
    background-color: transparent;
  }
  .video_list_in .list_in li a .list-vid {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
  .video_list_in .list_in .icon-v {
    width: 24px;
    height: 24px;
    text-align: center;
    line-height: 29px;
    margin-right: 10px;
  }
  .list-left {
    display: flex;
    align-items: center;
  }
  .icon-v svg {
    color: #eb4a1c;
    font-size: 25px;
  }
  .vid-title span {
    font-size: 14px;
    color: #2f373b;
    font-weight: 600;
    display: block;
    line-height: 22px;
  }
  .vid-title p {
    font-size: 14px;
    color: #878b8d;
    margin-bottom: 0px;
    line-height: normal;
  }
  .vid-time span {
    font-size: 14px;
    color: #878b8d;
  }
  
  .video-modal-up {
    min-height: 530px;
    position: relative;
  }
  .video-modal-up p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    font-weight: 600;
  }
  .video-modal {
    border-radius: 8px;
    overflow: hidden;
  }
  