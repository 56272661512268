/* kuber logo */
.logo {
    height: 38px;
  }
  .kuber-logo-container {
    display: flex;
    justify-content: center;
    padding: 30px;
  }
  /* Common System Loader */
  .loader {
    position: absolute;
    left: 48%;
    top: 50%;
    z-index: 1000;
  }
  /* left sider navigation */
  .fixedSider {
    background: #ffff;
    height: 100vh;
    overflow: auto;
    position: fixed;
    left: 0;
  }
  ::-webkit-scrollbar {
    width: 0px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
  
  /* mobile responsive */
  .responsiveSider {
    background: #ffff;
    height: 100vh;
  }
  
  /* main content  */
  .content {
    padding: 30px;
    overflow: initial;
    background: #f5f7fa;
    min-height: 100vh;
  }
  .mobileContent {
    overflow: initial;
    height: 100vh;
    padding: 10px;
    background: #f5f7fa;
  }
  
  .container {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  
  /* header css */
  
  .desktop-header {
    position: fixed;
    z-index: 1;
    width: 100%;
    background: #f5f7fa;
    padding-left: 22px;
    margin-bottom: 10px;
  }
  .ant-layout-sider-zero-width-trigger {
    z-index: 99;
  }
  .mobile-header {
    width: 100%;
    background: #f5f7fa;
    padding-left: 22px;
    margin-bottom: 10px;
  }
  .ant-menu.kuber_leftbar_menu {
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 120px;
  }
  .ant-menu.kuber_leftbar_menu li.ant-menu-item-selected {
    background-color: transparent;
  }
  .ant-menu.kuber_leftbar_menu.ant-menu-inline .ant-menu-item::after {
    left: 0;
    right: auto;
    border-left: 4px solid #eb4a1c;
    border-right: none;
  }
  .ant-menu.kuber_leftbar_menu li {
    min-height: 38px;
  }
  .ant-menu.kuber_leftbar_menu li .ant-menu-submenu-title {
    height: 50px;
    line-height: 48px;
    margin: 0px;
  }
  .ant-menu.kuber_leftbar_menu li .ant-menu-sub .ant-menu-item {
    height: 30px;
    line-height: 30px;
    margin: 0px;
    font-size: 15px;
    font-weight: 600;
    padding-left: 70px !important;
  }
  .top_bar_main {
    background: #f5f7fa;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    line-height: 1;
    padding: 0 30px;
    height: 72px;
    position: relative;
    z-index: 10;
  }
  .search-bar .ant-input-search .ant-input-group .ant-input {
    background: none;
    border: none;
    padding-left: 35px;
    font-size: 16px;
    color: #2f373b;
    padding: 10px 18px 10px 40px;
  }
  .ant-input:focus {
    box-shadow: none;
  }
  .search-bar .ant-input-search .ant-input-group .ant-input-group-addon {
    position: absolute;
    left: 0px;
    top: 6px;
  }
  .search-bar .ant-input-search .ant-input-group .ant-input-group-addon .ant-btn {
    background: none;
    border: none;
    box-shadow: none;
  }
  
  .top_right_bar ul li {
    display: inline-block;
    margin-left: 20px;
    font-size: 16px;
  }
  .user_profile {
    max-width: 190px;
    position: fixed;
    background: #ffffff;
    padding: 15px;
    box-shadow: 5px 5px 35px #cecece;
    bottom: 30px;
    left: 25px;
    border-radius: 8px;
  }
  .user_profile .user_inner {
    display: flex;
    justify-content: space-between;
  }
  .user_inner .user_img {
    margin-right: 5px;
  }
  .user_in_img {
    max-width: 40px;
    border-radius: 8px;
  }
  .user_dsc span {
    font-size: 15px;
    font-weight: 600;
    color: #2f373b;
    line-height: 19px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-transform: capitalize;
    display: block;
    width: 100px;
  }
  .user_dsc p {
    margin: 0px;
    font-size: 14px;
    color: #878b8d;
    line-height: 19px;
  }
  .user_icon span svg {
    width: 22px;
    height: 28px;
  }
  .user_link {
    height: 0px;
    opacity: 0;
    transition: 0.15s ease-out;
  }
  .user_link a {
    display: block;
    line-height: 30px;
    font-size: 14px;
    font-weight: 600;
    color: #2f373b;
    padding-top: 10px;
  }
  .user_link a:last-child {
    padding-top: 0px;
  }
  .user_hv {
    overflow: hidden;
    transition: width 2s;
  }
  .user_hv:hover .user_link {
    height: 100%;
    opacity: 1;
    transition: 0.15s ease-out;
  }
  
  .ant-select.bar_icon_text .ant-select-selector {
    padding: 0px;
  }
  .ant-select.bar_icon_text span.ant-select-arrow {
    margin-left: 15px;
    right: 4px;
  }
  .bar_icon_text {
    display: flex;
    align-items: center;
  }
  .bar_icon_text span {
    margin-left: 8px;
  }
  .lg_list {
    width: 120px;
    padding: 5px 12px;
  }
  .lg_list a {
    display: block;
    font-size: 16px;
    color: #2f373b;
    font-weight: 500;
  }
  .lg_list a:hover {
    color: #eb4a1c;
  }
  .set_bx span {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .set_bx span span {
    margin-left: 8px;
  }
  .bar_icon_text .ant-badge .ant-badge-count {
    border-radius: 6px;
    width: 26px;
    top: -5px;
    background: #eb4a1c;
  }
  .bar_icon_text .ant-badge .ant-badge-count .ant-scroll-number-only {
    margin-right: 6px;
    position: absolute;
    left: 0;
    right: 0;
  }
  .all_pd {
    flex: auto;
    min-height: 0;
    overflow-x: hidden;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .main_layout {
    position: relative;
    height: 100vh;
    overflow-x: hidden;
  }
  .db_icon {
    fill: none;
  }
  .b {
    fill: none;
  }
  .b {
    stroke: #2f373b;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
  }
  .ant-menu.kuber_leftbar_menu li svg {
    margin-right: 15px;
  }
  .ant-menu.kuber_leftbar_menu li svg.couponicon {
    margin-right: 23px;
  }
  .ant-menu.kuber_leftbar_menu li svg.reporticon {
    margin-right: 23px;
  }
  .ant-menu.kuber_leftbar_menu li.ant-menu-item-selected svg .b {
    stroke: #eb4a1c;
  }
  .ant-menu.kuber_leftbar_menu li:hover svg .b {
    stroke: #eb4a1c;
  }
  .sub_icon {
    display: flex;
    align-items: center;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .user_profile {
      position: absolute;
    }
    .bar_icon_text span + span {
      display: none;
    }
    .set_bx span span {
      display: none;
    }
    .product_list_bx {
      display: block;
    }
    .product_list_bx .left_product_r,
    .product_list_bx .right_product_r {
      width: 100%;
    }
  }
  @media only screen and (max-width: 767px) {
    .top_right_bar {
      margin-top: 15px;
    }
    .bar_icon_text span + span {
      display: none;
    }
    .set_bx span span {
      display: none;
    }
    .mobileContent {
      margin-top: 30px;
    }
    .recent_orders_table {
      width: 100%;
      padding: 10px;
      overflow-y: hidden;
      -ms-overflow-style: -ms-autohiding-scrollbar;
      border: solid 1px #e8e8e8;
      border-radius: 6px;
    }
    .box_products_card .product_list_bx {
      margin-bottom: 8px;
    }
  }
  